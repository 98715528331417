.master-detail {
    height: 100%;
}

.master-detail header {
    height: 60px;
}

.master-detail header .u-pull-left {
    display: flex;
}

.page-body header .breadcrumb {
    padding: 0px;
    margin: 0px;
    background: transparent;
    color: grey;
}

.page-body > header {
    display: flow-root;
    margin-bottom: 20px;
}

.master-detail .list-count {
    margin-left: 40px;
    font-size: 12px;
    color: gray;
}

.master-detail > .row {
    height: calc(100% - 60px);
    overflow: auto;
}

.list {
    border: none;
}

.list .list-header > span {
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    color: gray;
}

.list .list-item {
    padding: 10px 0px;
    background: white;
    margin: 7px 0px;
}

.list .list-item > span:not(.actions) {
    text-align: left;
}

.list .actions > a {
    cursor: pointer;
    margin-right: 7px;
    font-size: 17px;
    color: grey;
}

.list .actions > a:hover {
    color: darkgray;
}

.master-detail .editor form {
    text-align: left;
}

.button-container {
    margin-top: 15px;
}

.button-container .cancel {
    margin-left: 15px;
    cursor: pointer;
}

.error-explain {
    margin-left: 10px;
}
