























































.register-testimonials {
    display: none;
    color: #222;
    width: 300px;

    .fa-check {
        color: green;
    }

    .features {
        font-size: 16px;

        li {
            list-style-type: none;
            margin-bottom: 1rem;
        }
    }

    .testimonials {
        > li {
            border-top: 1px solid #d3d3d3;
            margin-top: 20px;
            padding: 20px 0 10px;
        }

        .content {
            margin-bottom: 15px;
            text-align: justify;
        }

        img {
            float: left;
            margin-right: 15px;
        }
    }
}
