






















































































































































p.em-c-explain {
    margin-top: 20px;
}

.em-c-back {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-top: 8px;
    border: 2px solid #e2e2e2;
    padding: 10px;
    border-radius: 3px;
}

.email {
    position: relative;
}

.email p {
    position: absolute;
    visibility: hidden;
    top: -76px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 10px;
    width: 195px;
    right: -68px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateY(6px);
    opacity: 0;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.email.tt-open p {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.email svg {
    position: absolute;
    right: 14px;
    top: 15px;
    opacity: 0.2;
    width: 12px;
    cursor: pointer;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.email svg:hover {
    opacity: 1;
}

body.register {
    #app .auth-wrapper {
        @media (min-width: 900px) {
            top: 20%;

            .register-testimonials {
                display: block;
                float: right;
                margin-top: 20px;
            }
        }

        > div {
            width: 800px;
            margin: 0 auto;

            .auth-container {
                @media (min-width: 900px) {
                    width: 450px;
                    float: left;
                    margin-top: 20px;
                    padding: 25px;

                    > div {
                        width: 100%;
                        padding: 0;
                    }

                    form {
                        margin-top: 0px;
                    }

                    input {
                        max-width: inherit;
                    }
                }

                .suggest-login {
                    font-size: 13px;
                    color: #222;

                    a {
                        color: #4B68AF;
                        font-size: 13px;
                    }
                }

                .option-panel {
                    padding: 10px 0px;
                    font-size: 11px;
                    text-align: center;

                    a {
                        color: #222;
                        font-size: 11px;
                        text-decoration: underline;
                    }
                }

                .fa-envelope {
                    font-size: 1.5em;
                    margin-top: 9px;
                }
            }
        }
    }
}
