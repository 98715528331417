#app-navigation {
    z-index: 9990 !important;
}

/***************
****************
***************/

#app .auth-wrapper h5 {
    font-size: 16px;
}

#app .auth-wrapper .auth-container {
    background: #fff;
    margin: 20px auto;
    width: 500px;
    text-align: center;
    padding: 35px 65px 45px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    position: relative;
}

#app .auth-wrapper .option-panel {
    background: #f5f8fa;
    height: 70px;
    left: 0px;
    position: absolute;
    width: 100%;
    padding: 5px 20px;
    bottom: 0px;
    text-align: left;
    font-size: 14px;
}

#app .auth-container > div {
    width: 350px;
    padding: 0 10px;
    margin: 0 auto 70px;
}

#app .app-auth .vue-alert {
    margin: 0 auto;
    top: 100px;
    right: 0;
    left: 0;
}

#app .auth-wrapper {
    min-height: calc(100% - 323px);
    position: relative;
}

#app .auth-wrapper .auth-container h1 {
    color: #4B68AF;
    font-size: 1.55em;
    text-align: center;
    margin-bottom: 0px;
}

#app .auth-wrapper .lost h1,
#app .auth-wrapper .logout h1 {
    margin-bottom: 5px;
}

#app .auth-wrapper .auth-container p {
    text-align: center;
    font-size: 13px;
    color: black;
}

#app .auth-wrapper .auth-container a:hover {
    color: #4B68AF;
}

#app .auth-wrapper .auth-container form {
    margin-bottom: 20px;
    margin-top: 25px;
    position: relative;
    text-align: left;
}

#app .auth-wrapper .auth-container span {
    width: 100%;
}

#app .auth-wrapper .auth-container svg {
    position: absolute;
    margin-left: 15px;
    margin-top: 15px;
    color: slategray;
}

#app .auth-wrapper .auth-container input[type=text],
#app .auth-wrapper .auth-container input[type=email],
#app .auth-wrapper .auth-container input[type=password] {
    width: 100%;
    border-radius: 3px;
    font-size: 13px;
    line-height: 20px;
    height: 40px;
    border: 1px solid #e6ecf0;
    border-radius: 3px;
    padding: 4px 0 0 40px;
    margin: 0 10px 10px 0;
    max-width: 280px;
    outline: 0;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}

#app .auth-wrapper .auth-container input.error {
    border: 1px solid #DA2D2D;
    color: #CA0C0C;
    box-shadow: 0px 0px 15px rgba(255, 0, 0, 0.08);
}

#app .auth-wrapper .auth-container input[type="submit"] {
    box-shadow: none;
    color: #fff;
    background: #0ac4ac;
    border: none;
    text-transform: none;
    letter-spacing: none;
    font-size: 11px;
    letter-spacing: 1.5px;
    font-family: "brandon-grotesque", sans-serif;
    text-transform: uppercase;
    margin-bottom: 0;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

#app .auth-wrapper .auth-container input[type="submit"]:hover {
    background: #4BB53C;
}

#app .auth-wrapper .auth-container a {
    text-decoration: none;
    color: #4B68AF;
    font-size: 15px;
}

#app-header,
#app .auth-wrapper .auth-container,
#app-header .user-dropdown,
#app-header .credits, {
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

#app .auth-wrapper .non-human-panel {
    display: inline-block;
}

#app .auth-wrapper .non-human-panel .u-pull-left {
    width: 25%;
}

#app .auth-wrapper .non-human-panel .u-pull-left img {
    margin-top: 20px;
    width: 100%;
}

#app .auth-wrapper .non-human-panel .u-pull-right {
    width: 70%;
}

#app .auth-wrapper .non-human-panel .u-pull-right img {
    width: 60%;
}

#app .auth-wrapper .non-human-panel .g-recaptcha {
    width: 100%;
    opacity: 0.8;
    filter: alpha(opacity=80);
}

#app .auth-wrapper .non-human-panel .g-recaptcha > div {
    margin: 0 auto;
}

@media screen and (max-width: 450px) {
    #app .auth-wrapper .auth-container {
        padding: 30px 20px 40px 20px;
        width: 100%;
        left: 0;
        margin-left: 0;
    }
}

@media screen and (max-height: 450px) {
    #app-header.state-unauth {
        position: relative;
        overflow-y: scroll;
    }
}

@media (max-height: 900px) {
    .auth-wrapper {
        height: 600px;
    }
}

@media (max-width: 991px) {
    #rc-imageselect {
        transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }

    .non-human-panel .u-pull-left {
        display: none;
    }

    #app .auth-wrapper .non-human-panel .u-pull-right {
        width: 100%;
        float: initial;
    }

    #app .auth-wrapper > .auth-container {
        padding: 35px 15px 45px;
        width: initial;
        margin-left: 0px;
        left: initial;
    }
}
